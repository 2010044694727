$(function() {

	/* hide navi while scrolling, currently uncommented */
	// $("#mainNav").autoHidingNavbar();

	$("#mainNav").on("show.bs.collapse", function (e) {
		$("#mainNav").css("background-color", "rgb(169,167,147)");
	});

	$("#mainNav").on("hidden.bs.collapse", function (e) {
		$("#mainNav").css("background-color", "");
	});

	$('.booking').on('click', function (event) {
		var theID = $(this).data('id');
		$('#dlg-' + theID).slideToggle('show');
	});

});